import { Button, Heading, Text, VStack } from '@fluidtruck/core'
import Header from 'components/header'
import SEO from 'components/seo'
import NextLink from 'next/link'
import * as React from 'react'
import { FaHome } from 'react-icons/fa'

const NotFoundPage = () => {
  return (
    <>
      <SEO
        description="Not Found"
        title="Uh Oh!"
      />
      {/* <AdBanner /> */}
      <Header />
      <VStack
        as="section"
        justify="center"
        mt={['20', null, '40']}
        spacing="4"
        textAlign="center"
      >
        <Heading>Not Found</Heading>
        {/* <Text fontSize={{ md: 'xl' }}>Not Found</Text> */}
        <NextLink href="/" passHref>
          <Button
            aria-label="Back to Home"
            as="a"
            colorScheme="teal"
            leftIcon={<FaHome />}
            size="lg"
          >
            Back to home
          </Button>
        </NextLink>
      </VStack>
    </>
  )
}

export default NotFoundPage
